import React from "react";
import "./Team.css";

import ikePhotoSmall from "../../assets/images/team/ike-small.jpg";
import ikePhoto from "../../assets/images/team/ike.jpg";
import samPhotoSmall from "../../assets/images/team/sam-small.jpg";
import samPhoto from "../../assets/images/team/sam.jpg";
import jeremyPhotoSmall from "../../assets/images/team/jeremy-small.jpg";
import jeremyPhoto from "../../assets/images/team/jeremy.jpg";
import ronniePhotoSmall from "../../assets/images/team/ronnie-small.jpg";
import ronniePhoto from "../../assets/images/team/ronnie.jpg";

const Team = () => {
  const currentYear = new Date().getFullYear();
  const teamMembers = [
    {
      name: "Ike Kutter",
      email: "ike@cvfdenver.com",
      title: "AIF®",
      link: "https://brokercheck.finra.org/individual/summary/5276187",
      photo: ikePhoto,
      photoSmall: ikePhotoSmall,
      bio: `As the owner of ClearView Financial, LLC, Ike enjoys working with clients to achieve their life destination and plan for a happy and healthy retirement. As a Registered Representative, he is licensed to offer securities through Triad Advisors, LLC, Member FINRA and SIPC. He also holds an insurance license in the State of Colorado, as well as many other states in the U.S. Ike is also an Accredited Investment Fiduciary (AIF).
      \nIke is originally from South Dakota and attended Colorado State University, where he received his Bachelor’s Degree. Ike is a hunter, fisherman, and an all-around family man. He and his lovely wife, Pamela, and their son, Jake, are avid fans of the outdoors and enjoy trips into the beautiful Rocky Mountains.
      \nAs a planner, he has worked with affluent clients for almost a decade and often shares as a presenter at financial seminars.
      \nIke’s passion is to work with small business owners and professionals and help them to establish the most effective strategies to manage their income and capital, and manage any taxable consequences.
      \nIke is committed to making ClearView Financial, LLC the most trusted and efficient financial firm along the Front Range of Colorado and throughout the nation.`,
    },
    {
      name: "Samuel Keck",
      email: "sam@cvfdenver.com",
      title: "MBA, CLU, CFP®, LUTCF",
      link: "https://brokercheck.finra.org/individual/summary/1147948",
      photo: samPhoto,
      photoSmall: samPhotoSmall,
      bio: `Sam is from St. Joseph, Missouri and moved to Colorado in 1972 after serving five years in the United States Air Force. Sam is married to his beautiful life partner Mary, a Registered Nurse and Wellness Consultant. They have three children and seven grandchildren.
      \nSam is a graduate of Graceland University, Iowa, where he received his Bachelor of Arts Degree with emphasis in Finance and Economics. He was also a star athlete in cross country and track & field and finished fifth in the mile run at the National Track and Field championships in Sioux Falls, South Dakota. After graduation from Graceland University Sam went on to complete a Masters in Business Administration Degree with emphasis in Insurance from Indiana University. Upon graduation he entered the Air Force and served in Thailand and Germany as a transportation officer. During that time he also taught business courses for the world wide campus of the University of Maryland.
      \nSam is a Certified Financial Planner (CFP), Chartered Life Underwriter (CLU), as well as an Accredited Investment Fiduciary (AIF). His current business focus is on cash flow retirement and estate planning. Sam is licensed for securities in Colorado, Idaho, North Carolina, Texas and Utah; and insurance licensed in Colorado, Utah, Arizona, California, Florida, Idaho, and Nevada. Sam enjoys worldwide travel, golf, skiing, camping (as long as it’s a Four Seasons Resort) and spending time with his wife, children and grandchildren. We are pleased to welcome Sam into our network of financial professionals.`,
    },
    {
      name: "Jeremy Fortin",
      email: "jeremy@cvfdenver.com",
      photo: jeremyPhoto,
      photoSmall: jeremyPhotoSmall,
      title: "Wealth Management Advisor, MBA, AIF",
      link: "https://brokercheck.finra.org/individual/summary/6170131",
      bio: `Jeremy has been in the financial services industry for the last ${
        currentYear - 2013
      } years.  He brings with him a passion to help small business owners, families and individuals create plans that help them reach their goals in the most efficient way possible. 
      \nPrior to joining ClearView Financial, LLC, Jeremy worked on the buy-side of private equity. He focused on acquiring commercial aircrafts, railroads, and distressed assets. Jeremy and his family lived in London where he helped source capital to finance aircrafts and build relationships for buying opportunities within the commercial aircraft industry. He has also advised on the acquisition and restructuring of corporate assets out of bankruptcy.
      \nJeremy graduated from the University of Nebraska with a Bachelor of Science in Accounting and Finance. He earned his Masters of Business Administration at the University of Denver. As a Registered Representative, he is licensed to offer securities through Triad Advisors, LLC, Member FINRA and SIPC. He also holds an insurance license in the State of Colorado, as well as a number of other states in the U.S. Jeremy is also an Accredited Investment Fiduciary (AIF).
      \nHe spends as much time as possible outdoors and enjoys fly fishing, golfing, hunting, and about any other outdoor activity. When not outside, you may find him in a bowling alley trying to perfect his bowling game. Jeremy lives in Lone Tree with his two beautiful daughters. They keep him busy with a full schedule of swimming, dance and gymnastics. He is active in his community and enjoys volunteering for various positions within his daughters’ school, as well as serving on the Leadership Team for his local chapter of Ducks Unlimited.`,
    },
    {
      name: "Ronnie Lee Johnson",
      email: "ronnie@cvfdenver.com",
      title: "",
      photo: ronniePhoto,
      photoSmall: ronniePhotoSmall,
      bio: `Ronnie Lee Johnson is an estate planner, ordained minister, life underwriter, published author, painter, and seminar presenter. He was born in Center, Texas—what he calls Deep East Texas. Ronnie has two children and nine grandchildren.\n\nHe now works remotely with our CVF office from North Padre Island in Corpus Christi, Texas.\n\nHe has conducted seminars across the country and internationally for the past 25 years. His audiences include: schools, colleges, churches, corporations, and cruise ships.\n\nRonne has described his life ministry and career compared to a stick of butter cut perfectly in two pieces: the first half as a pastor and the second half in financial planning.\n\nRonnies favorite quote is by Mark Twain, “Do what is right, this will gratify some people and astonish the rest.”\n\nAmong some of his published works are:`,
      list: [
        "You Are Loved",
        "You Are Loved, Too",
        "Nettles",
        "Is Your House in Order",
        "Money Talks",
        "Fritz, The Funniest Monkey Ever",
        "Memos from God",
      ],
    },
  ];

  return (
    <section id="team" className="team">
      <h1>The Team</h1>
      <ul className="teamList">
        {teamMembers.map((member, index) => {
          return <TeamMember member={member} key={index} />;
        })}
      </ul>
    </section>
  );
};

const TeamMember = ({ member }) => {
  const bioRef = React.useRef(null);
  const titleRef = React.useRef(null);
  const [bioHeight, setBioHeight] = React.useState("");

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => {
    setBioHeight(isOpen ? "" : `${bioRef.current.scrollHeight}px`);
    setIsOpen(!isOpen);
    if (isOpen) {
      window.scrollTo({ top: titleRef.current.offsetTop, behavior: "smooth" });
    }
  };

  return (
    <li className="teamMember">
      <div className="teamInfoContainer" ref={titleRef}>
        <div className="teamTextContainer">
          <h2>{member.name}</h2>
          <p className="teamTitle">{member.title}</p>
          <a href={`mailto:${member.email}`} className="teamEmail">
            {member.email}
          </a>
          {member.link ? (
            <a href={member.link} className="finraLink">
              BrokerCheck Profile
            </a>
          ) : null}
        </div>
        <div className="teamPhotoContainer">
          <picture>
            <source
              srcSet={member.photo}
              media="(min-width: 600px)"
              width="300px"
              height="450px"
            ></source>
            <img
              className="teamPhoto"
              src={member.photoSmall}
              width="150px"
              height="225px"
              loading="lazy"
              alt=""
            ></img>
          </picture>
          {/* <img 
            className="teamPhoto"
            srcSet={`
              ${member.photoSmall} 150w,
              ${member.photo} 300w
            `}
            sizes="(max-width: 600px) 150px,
              300px"
            src={member.photo} 
            alt=''
            width="300px"
            height="450px"
            loading="lazy"
          >
          </img> */}
        </div>
      </div>
      <div
        className={`teamBioContainer${isOpen ? " open" : ""}`}
        style={{ maxHeight: bioHeight }}
        ref={bioRef}
      >
        <p className="teamBio">{member.bio}</p>
        {member.list ? (
          <ul className="teamBio bioList">
            {member.list.map((item, i) => (
              <li key={i} className="teamBioListItem">
                {item}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <button type="button" className="readMoreButton" onClick={toggleOpen}>
        {isOpen ? "- Read Less" : "+ Read More"}
      </button>
    </li>
  );
};

export default Team;
