import React from "react";
import "./App.css";
import Hero from "./components/Hero/Hero";
import Network from "./components/Network/Network";
import About from "./components/About/About";
import Process from "./components/Process/Process";
import Team from "./components/Team/Team";
import Contact from "./components/Contact/Contact";

const App = () => {
  return (
    <div className="App">
      <Hero />
      <About />
      <Network />
      <Process />
      <Team />
      <Contact />
    </div>
  );
};

export default App;
