import React from "react";
import "./SlideReel.css";
import Icon from "../Icon/Icon";

const SlideReel = ({ slides }) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const moveNextSlide = () => {
    const slideCount = slides.length;
    setActiveSlide((activeSlide + 1) % slideCount);
  };

  const movePrevSlide = () => {
    const slideCount = slides.length;
    setActiveSlide(
      (((activeSlide - 1) % slideCount) + slideCount) % slideCount
    );
  };

  const slidesRef = React.useRef(null);
  const dragProps = React.useRef();
  const [isDragging, setIsDragging] = React.useState(false);

  const initializeDrag = (event) => {
    setIsDragging(true);
    const { clientX } = event;
    const dragPosition = clientX || event.targetTouches[0].clientX;
    dragProps.current = {
      dragStart: dragPosition,
    };

    if (event.type === "mousedown") {
      window.addEventListener("mousemove", startDragging, false);
      window.addEventListener("mouseup", stopDragging, false);
    } else if (event.type === "touchstart") {
      window.addEventListener("touchmove", startDragging, false);
      window.addEventListener("touchend", stopDragging, false);
    }
  };

  const startDragging = ({ type, clientX, targetTouches }) => {
    if (type === "touchmove") {
      dragProps.current = {
        ...dragProps.current,
        dragStop: targetTouches[0].clientX,
      };
    }

    const dragPosition = clientX || targetTouches[0].clientX;
    const isAtFirstSlide =
      dragPosition > dragProps.current.dragStart && activeSlide === 0;
    const isAtLastSlide =
      dragPosition < dragProps.current.dragStart &&
      activeSlide === slides.length - 1;

    if (isAtFirstSlide) {
      slidesRef.current.style.marginLeft = `${
        0.165 * (dragPosition - dragProps.current.dragStart)
      }px`;
    } else if (isAtLastSlide) {
      slidesRef.current.style.marginLeft = `${
        activeSlide * -width +
        (2 * 0.165 * (dragPosition - dragProps.current.dragStart)) / 2
      }px`;
    } else {
      slidesRef.current.style.marginLeft = `${
        activeSlide * -width + (dragPosition - dragProps.current.dragStart)
      }px`;
    }
  };

  const stopDragging = ({ type, clientX }) => {
    const minSwipeDistance = 75;
    const dragStop = clientX || dragProps.current.dragStop;
    const swipeDistance = dragProps.current.dragStart - dragStop;
    const isLeftSwipe = swipeDistance > minSwipeDistance;
    const isRightSwipe = swipeDistance < -minSwipeDistance;

    if (isLeftSwipe) {
      if (activeSlide !== slides.length - 1) {
        moveNextSlide();
      }
    } else if (isRightSwipe) {
      if (activeSlide !== 0) {
        movePrevSlide();
      }
    }
    setIsDragging(false);
    slidesRef.current.style.marginLeft = `${activeSlide * -width}px`;

    if (type === "mouseup") {
      window.removeEventListener("mousemove", startDragging, false);
      window.removeEventListener("mouseup", stopDragging, false);
    } else if (type === "touchend") {
      window.removeEventListener("touchmove", startDragging, false);
      window.removeEventListener("touchend", stopDragging, false);
    }
  };

  const useContainerWidth = (ref) => {
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
      const getWidth = () => ref.current.offsetWidth;
      const handleResize = () => {
        setIsDragging(true);
        setWidth(getWidth());
      };

      window.addEventListener("resize", handleResize);
      handleResize();
      setIsDragging(false);

      return () => window.removeEventListener("resize", handleResize);
    }, [ref]);

    return width;
  };

  const width = useContainerWidth(slidesRef);
  React.useEffect(() => {
    setIsDragging(false);
  }, [width]);

  return (
    <>
      <div className="slideViewport">
        <ol
          ref={slidesRef}
          style={{ marginLeft: activeSlide * -width }}
          className={`slides${isDragging ? " dragging" : ""}`}
        >
          {slides.map((slide, index) => {
            return (
              <Slide
                key={index}
                index={index}
                handleDrag={initializeDrag}
                slide={slide}
              />
            );
          })}
        </ol>
      </div>
      <Controls
        slideCount={slides.length}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
        moveNextSlide={moveNextSlide}
        movePrevSlide={movePrevSlide}
      />
    </>
  );
};

const Slide = ({ index, handleDrag, slide }) => {
  return (
    <li
      key={index}
      className={"slide noselect"}
      onMouseDown={handleDrag}
      onTouchStart={handleDrag}
    >
      <div className={`slide${index}`}>
        <div className="slideContents">
          <picture>
            <source type="image/svg+xml" srcSet={slide.icon}></source>
            <img
              src={slide.iconFallback}
              alt={slide.alt}
              className="slideIcon"
              draggable="false"
              loading="lazy"
              fetchpriority="low"
              width="200px"
              height="200px"
            ></img>
          </picture>
          <p className="slideText">{slide.text}</p>
        </div>
      </div>
    </li>
  );
};

const Controls = ({
  slideCount,
  activeSlide,
  setActiveSlide,
  movePrevSlide,
  moveNextSlide,
}) => {
  return (
    <div className="slideControls">
      <div className="slideControlDots">
        {[...Array(slideCount)].map((x, index) => {
          return (
            <button
              type="button"
              name={`slide${index + 1}`}
              className={`slideControlDot${
                activeSlide === index ? " active" : ""
              }`}
              onClick={() => setActiveSlide(index)}
              key={index}
            ></button>
          );
        })}
      </div>
      <div className="slideControlArrows">
        <button
          type="button"
          className="slideControlArrow"
          onClick={movePrevSlide}
        >
          <Icon iconName="arrow-left" className="icon" />
        </button>
        <button
          type="button"
          className="slideControlArrow"
          onClick={moveNextSlide}
        >
          <Icon iconName="arrow-right" className="icon" />
        </button>
      </div>
    </div>
  );
};

export default SlideReel;
