import "./Contact.css";
import Icon from "../Icon/Icon";

const Contact = () => {
  return (
    <section id="contact">
      <div className="contact">
        <h1>Contact Us</h1>
        <div className="contactContainer">
          <div className="infoContainer">
            <Icon iconName="email-unread" className="icon" />
            <a href="mailto:info@cvfdenver.com">info@cvfdenver.com</a>
            <Icon iconName="phone" className="icon" />
            <a href="tel:303-843-9820">(303) 843-9820</a>
            <Icon iconName="location" className="icon" />
            <a href="https://goo.gl/maps/oWXwR3ZoewXC3SLJ9">
              88 Inverness Circle East<br></br>Suite H-101<br></br>Englewood, CO
              80112
            </a>
          </div>
          <div className="mapContainer">
            <iframe
              title="map"
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.268583443665!2d-104.86200359999997!3d39.576096799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c85ec6a8611bf%3A0x6257d7b06877e15!2sClearView%20Financial%2C%20LLC!5e0!3m2!1sen!2sus!4v1659377689690!5m2!1sen!2sus"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
