import React from "react";
import "./Footer.css";

const Footer = ({ footerRef }) => {
  return (
    <footer ref={footerRef} id="footer" className="footer">
      <p>
        &copy; {new Date().getFullYear()} ClearView Financial, LLC. Securities
        offered through{" "}
        <span className="bold">Triad Advisors, LLC (Triad)</span>, member{" "}
        <a href="https://www.finra.org">FINRA</a>/
        <a href="https://www.sipc.org/">SIPC</a>. Investment Advisory services
        offered through{" "}
        <span className="bold">
          Osaic Advisory Services, LLC (Osaic Advisory).
        </span>{" "}
        <span className="bold">Triad</span> and{" "}
        <span className="bold">Osaic Advisory</span> are separately owned and
        other entities and/or marketing names, products or services referenced
        here are independent of <span className="bold">Triad</span> and{" "}
        <span className="bold">Osaic Advisory</span>.
      </p>
      <p>
        ClearView Financial, LLC is licensed in: CA, CO, FL, KY, MI, MO, NM, OK,
        PA, SC, TX, UT, WY
      </p>
    </footer>
  );
};

export default Footer;
