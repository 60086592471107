import "./Network.css";

import attorneysImg from "../../assets/images/network/attorneys.png";
import plannersImg from "../../assets/images/network/planners.png";
import repsImg from "../../assets/images/network/representatives.png";
import realtorsImg from "../../assets/images/network/realtors.png";
import advisorsImg from "../../assets/images/network/advisors.png";
import accountantsImg from "../../assets/images/network/accountants.png";

const Network = () => {
  const networkItems = [
    { name: "Attorneys", img: attorneysImg },
    { name: "Certified Financial Planners", img: plannersImg },
    { name: "Investment Advisor Representatives", img: repsImg },
    { name: "Realtors & Mortgage Brokers", img: realtorsImg },
    { name: "Insurance Advisors", img: advisorsImg },
    { name: "Certified Public Accountants", img: accountantsImg },
  ];

  return (
    <section id="network" className="network">
      <h1>Our Professional Network</h1>
      <ul>
        {networkItems.map((item, index) => {
          return (
            <li key={index}>
              <div className="networkImgContainer">
                <img
                  src={item.img}
                  alt=""
                  loading="lazy"
                  width="100px"
                  height="100px"
                ></img>
              </div>
              <h2>{item.name}</h2>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Network;
