import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Icon from "../Icon/Icon";
import "./Layout.css";

const Layout = ({ children }) => {
  const [isAtTop, setIsAtTop] = React.useState(true);

  React.useEffect(() => {
    window.onscroll = () => {
      const scrollPosition =
        document.body.scrollTop || document.documentElement.scrollTop;
      scrollPosition === 0 ? setIsAtTop(true) : setIsAtTop(false);
    };
  });

  const ref = React.useRef(null);

  const useContainerHeight = (ref) => {
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
      const getHeight = () => ref.current.offsetHeight;
      const handleResize = () => {
        setHeight(getHeight());
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, [ref]);

    return height;
  };

  const footerHeight = useContainerHeight(ref);

  return (
    <div className="layoutContainer">
      <Header/>
      <main className="content" style={{ paddingBottom: `${footerHeight}px` }}>
        {React.cloneElement(children, { footerHeight })}
      </main>
      <Footer height={footerHeight} footerRef={ref} />
      <ScrollToTopButton isAtTop={isAtTop} />
    </div>
  );
};

const ScrollToTopButton = ({ isAtTop }) => {
  const scrollToTop = () => {
    document.body.scrollTop = 0; // for Safari
    document.documentElement.scrollTop = 0;
  };

  return (
    <button
      type="button"
      id="scrollToTop"
      className={`scrollToTop${isAtTop ? "" : " shown"}`}
      onClick={scrollToTop}
    >
      <Icon iconName="arrow" className="icon" />
    </button>
  );
};

export default Layout;
