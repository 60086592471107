import "./About.css";

const About = () => {
  return (
    <section id="about">
      <div className="about">
        <h1>About Us</h1>
        <p>
          ClearView Financial, LLC is concerned about helping clients make the
          right choices from the unique options available to them. By offering a
          full service, one-stop approach, we assist clients in a more
          meaningful, comprehensive approach to lifestyle planning. Through our
          process, we help our clients identify their true life dreams, helping
          them establish concrete goals and an action plan for helping them
          achieve their goals.
        </p>
      </div>
    </section>
  );
};

export default About;
