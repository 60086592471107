import React from "react";
import "./Process.css";
import SlideReel from "../SlideReel/SlideReel";

import dreamsImg from "../../assets/images/process/dreams.svg";
import dreamsImgFallback from "../../assets/images/process/dreams.png";
import optionsImg from "../../assets/images/process/options.svg";
import optionsImgFallback from "../../assets/images/process/options.png";
import networkImg from "../../assets/images/process/network.svg";
import networkImgFallback from "../../assets/images/process/network.png";
import committmentImg from "../../assets/images/process/committment.svg";
import committmentImgFallback from "../../assets/images/process/committment.png";

const Process = () => {
  const processes = [
    {
      text: "We listen carefully to your dreams",
      alt: "Line art of a dream bubble with a mountain inside of it",
      icon: dreamsImg,
      iconFallback: dreamsImgFallback,
    },
    {
      text: "We educate and inform you about your options",
      alt: "Line art of a magazine with images of a mountain and a down jacket",
      icon: optionsImg,
      iconFallback: optionsImgFallback,
    },
    {
      text: "We engage our professional network in areas that are priorities for you and your family",
      alt: "Line art of a mountain at sunrise",
      icon: networkImg,
      iconFallback: networkImgFallback,
    },
    {
      text: "We facilitate the process and oversee these relationships as part of our committment to you ",
      alt: "Line art of a mountain with a flag planted at the summit",
      icon: committmentImg,
      iconFallback: committmentImgFallback,
    },
  ];

  return (
    <section id="process" className="process">
      <h1>Our Process</h1>
      <SlideReel slides={processes} />
    </section>
  );
};

export default Process;
