import React from "react";
import { useRouteError } from "react-router-dom";
import "./ErrorPage.css";
// import errorIcon from "../../assets/images/error/error.svg";
// import errorIconFallback from "../../assets/images/error/error.png";

const ErrorPage = ({ footerHeight }) => {
  const error = useRouteError();
  console.log(footerHeight);

  return (
    <div
      id="errorPage"
      className="errorPage"
      style={{ minHeight: `calc(100vh - ${footerHeight + 66.66}px)` }}
    >
      <div className="errorTextContainer">
        <h1 className="errorStatus">{error.status}</h1>
        <p>Sorry, an error has occurred:</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <a href="/" className="errorLink">
          Take me home
        </a>
      </div>
      {/* <picture>
        <source type="image/svg+xml" srcSet={errorIcon}></source>
        <img
          src={errorIconFallback}
          alt="Line art of a compass"
          className="errorIcon"
          draggable="false"
          loading="lazy"
          fetchpriority="low"
          width="250px"
          height="250px"
        ></img>
      </picture> */}
    </div>
  );
};

export default ErrorPage;
