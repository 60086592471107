import React from "react";
import "./Hero.css";
import flagLogo from "../../assets/images/flag-logo.svg";
import flagLogoFallback from "../../assets/images/flag-logo.png";
import backgroundImage from "../../assets/images/lake-catamount.avif";
import backgroundImageAlt from "../../assets/images/lake-catamount.webp";
import backgroundImageFallback from "../../assets/images/lake-catamount.png";
import crsPdf from "../../assets/files/THS-24-0071 OAS Form CRS FINAL.pdf";

import Icon from "../Icon/Icon";

const Hero = () => {
  const [backgroundUrl, setBackgroundUrl] = React.useState(null);

  const detectImgFormatSupport = (filetype, callback) => {
    const testImage =
      filetype === "avif"
        ? "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
        : "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==";
    var img = new Image();
    img.src = testImage;
    img.onload = () => {
      var result = img.width > 0 && img.height > 0;
      callback(result);
    };
    img.onerror = () => {
      callback(false);
    };
  };

  detectImgFormatSupport("avif", (avifResult) => {
    if (avifResult === true) {
      setBackgroundUrl(backgroundImage);
    } else {
      detectImgFormatSupport("webp", (webpResult) => {
        if (webpResult === true) {
          setBackgroundUrl(backgroundImageAlt);
        } else {
          setBackgroundUrl(backgroundImageFallback);
        }
      });
    }
  });

  return (
    <section
      id="hero"
      style={
        backgroundUrl === null
          ? { backgroundImage: `linear-gradient(lightblue, white)` }
          : {
              backgroundImage: `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)), url(${backgroundUrl})`,
            }
      }
    >
      <div className="hero">
        <div className="heroTextContainer">
          <h1>
            ClearView<br></br>Financial, LLC
          </h1>
          <p>
            empowers clients with the knowledge to envision a clear view to
            their future life and wealth
          </p>
          <p className="finra">
            Check the background of our investment professionals on{" "}
            <a href="https://brokercheck.finra.org/">FINRA's BrokerCheck</a>.
          </p>
          <p className="finra">
            <a
              className="crs"
              href={crsPdf}
              target="_blank"
              rel="noreferrer"
              download="OAS Form CRS.pdf"
            >
              OAS Form CRS
              <Icon iconName="download" className="icon" />
            </a>
          </p>
          <a href="#contact">
            <button type="button">Contact Us</button>
          </a>
        </div>
        <div className="heroImgContainer">
          <picture>
            <source type="image/svg+xml" srcSet={flagLogo}></source>
            <img
              src={flagLogoFallback}
              alt=""
              width="350"
              height="350"
              fetchpriority="high"
            ></img>
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Hero;
