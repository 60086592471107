import React from "react";
import "./Header.css";

import logo from "../../assets/images/logo.svg";
import logoFallback from "../../assets/images/logo.png";
import Icon from "../Icon/Icon";

const Header = () => {
  const links = [
    { name: "About Us", href: "/#about" },
    { name: "Professional Network", href: "/#network" },
    { name: "Our Process", href: "/#process" },
    { name: "The Team", href: "/#team" },
    { name: "Contact Us", href: "/#contact" },
  ];

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const useScrollDetection = (ref) => {
    const prevScrollY = React.useRef(0);
    const blocking = React.useRef(false);

    React.useEffect(() => {
      const updateScrollPosition = () => {
        const scrollY = window.pageYOffset;
        if (Math.abs(scrollY - prevScrollY.current) >= 150) {
          setIsOpen(false);
          prevScrollY.current = scrollY;
        }
        blocking.current = false;
      };

      const onScroll = () => {
        if (!blocking.current) {
          blocking.current = true;
          window.requestAnimationFrame(updateScrollPosition);
        }
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }, [ref]);
  };

  const useOutsideClickDetection = (ref) => {
    React.useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const headerRef = React.useRef(null);
  useScrollDetection(headerRef);
  useOutsideClickDetection(headerRef);

  return (
    <header id="header" ref={headerRef} className={"header"}>
      <nav className={isOpen ? "open" : ""}>
        <a href="/" className="logo">
          <picture>
            <source type="image/svg+xml" srcSet={logo}></source>
            <img
              src={logoFallback}
              alt="ClearView Financial logo"
              width="160px"
              height="67px"
            ></img>
          </picture>
        </a>
        <div className="linkContainer">
          <button
            type="button"
            name="navigationMenu"
            className="icon"
            onClick={toggleOpen}
          >
            <Icon iconName={isOpen ? "cancel" : "menu"} className="svg" />
          </button>
          {links.map((link, index) => {
            return (
              <a href={link.href} onClick={() => setIsOpen(false)} key={index}>
                {link.name}
              </a>
            );
          })}
        </div>
      </nav>
    </header>
  );
};

export default Header;
